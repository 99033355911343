@import 'src/styles/variables';

.NotFoundView {
  text-align: center;

  h1 {
    font-size: 10rem;
    margin: 0;
  }
  h2 {
    font-size: 6rem;
    margin: 0;
  }

  a {
    color: $color_white;
    text-decoration: none;
    font-size: x-large;
  }

  .icon-go-back {
    width: 1rem;
    color: $color_purple;
  }
}
